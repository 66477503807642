import moment from 'moment';
import { _toast } from './dialogs';

if (window.LOCALE) {
  moment.locale(window.LOCALE);
}

let offset = 0;
let syncingLag = false;
let shownWarning = false;

export const syncLag = socket => {
  if (syncingLag) {
    return false;
  }
  syncingLag = true;
  const timeBeforeCall = Date.now();
  socket.emit('server-time', serverTime => {
    const timeAfterResponse = Date.now();
    const lag = (timeAfterResponse - timeBeforeCall) / 2;
    if (lag > 60000) { // Huge lag! makes the whole computation invalid
      setTimeout(() => syncLag(socket), 1000); // retry ASAP
      return;
    }
    // example:
    // timeBeforeCall = 14:45:46
    // timeAfterResponse = 14:45:50
    // → lag = 00:00:02
    // serverTime = 15:00:00
    // → offset = 15:00:00 + 00:00:02 - 14:45:50 = +00:14:12
    const previousOffset = offset;
    offset = serverTime + lag - timeAfterResponse;
    // Ask again for server time periodically
    syncingLag = false;
    setTimeout(() => syncLag(socket), 600000); // 10 minutes
    // If there is more than 10 minutes difference between server and client: show a warning
    // Do not show the warning again unless situation has changed more than 30%
    if (!previousOffset || Math.abs(offset - previousOffset) > Math.abs(previousOffset / 2)) {
      // Situation changed: reset dialog info
      shownWarning = false;
    }
    if (!shownWarning && Math.abs(offset) > 600000) {
      _toast(
        window.ndTranslations.serverTimeWarning
          .replace(/SERVER_TIME/, moment(serverTime).format('LTS'))
          .replace(/LOCAL_TIME/, moment(timeAfterResponse).format('LTS')),
        { error: true, timeout: 30000 }
      );
      shownWarning = true;
    }
    window.console && console.info({ offset }); // eslint-disable-line no-console
  });
  return true;
};

export const now = () => {
  return moment().add(offset, 'millisecond');
};

export default moment;
