'use strict';

/* eslint no-underscore-dangle:0, no-alert:0, quotes:0, indent:0 */
/* eslint-env browser */

import $ from 'jquery';
import confirmDisconnect from './libs/confirm-disconnect';
import _ from './libs/lodash';
import moment, { now as momentNow } from './libs/moment-sync';
import { socketConnect } from './libs/socket';
import { getLogoConfig } from './libs/style-params';
import './libs/ui-loading';

// Inject theme's logo
const logoTemplate = _.template($('#tpl-logo').text());
const updateLogo = () => {
  $('#event-logo').remove();
  $('#event-title').before(logoTemplate({ logo: getLogoConfig() }));
};
updateLogo();

const $sessions = $('#sessions-list'),
      templateSessions = _.template($("#tpl-session").text()),
      templateNoSession = _.template($("#tpl-noSession").text());

// var $liveSessions = $("#live-sessions");
// var $nextSessions = $("#next-sessions");
// var $testSessions = $("#test-sessions");
// var $testSessionsStaff = $("#test-sessions-staff");
// var $offSessions = $("#off-sessions");
const socket = socketConnect('lobby');

// Enable js-confirmDisconnect
confirmDisconnect(socket);

socket.on("lobby:sessions", update);

(function grabCurrentSessions () {
  socket.emit("lobby:current-sessions");
  setTimeout(grabCurrentSessions, 30000);
})();

// Display preloaded sessions
if (window.LOBBY_DATA) {
  update(...window.LOBBY_DATA);
}

// Open/close other sessions
// $("button#othersessionsbutton").click(function () {
//   $("div#non-live-sessions").toggle(500);
// });

// Update sessions list
function update (sessions, sessionShortURLPrefix) {
  var now = momentNow();

  for (var s in sessions) {
    sessions[s].sessionShortURLPrefix = sessionShortURLPrefix;
  }
  sessions = _.sortBy(sessions, ['begin']).forEach(session => {
    // is or will the session be still active today (session may last several days) ?
    session.stillactivetoday = now.isSameOrBefore(session.end, 'day') && moment(session.begin).isSameOrAfter(now) && now.isSameOrAfter(session.begin, 'day');
  });

  var LiveSessions = _.filter(sessions, { instance: 'live' });
  var NextSessions = _.filter(sessions, function (s) {
    return s.instance === 'test' || s.instance === 'prepare';
    //return s.instance === 'test' && s.stillactivetoday;
  });
  // var TestSessions = _.filter(sessions, function (s) {
  //   return s.instance === 'test' && !s.stillactivetoday && s.hasActiveModerator;
  // });
  // var TestSessionsStaff = _.filter(sessions, function (s) {
  //   return s.instance === 'test' && !s.stillactivetoday ;
  // });
  // var OffSessions = _.filter(sessions, { instance: 'off' });

  window.l = $sessions;
  window.s = sessions;

  if( LiveSessions.length == 0 && NextSessions.length == 0) {
    $sessions.html( templateNoSession() );
  }
  else {
    $sessions.html( templateSessions({ sessions: LiveSessions, dateformatlabel: dateformatlabel }) );
    $sessions.append( templateSessions({ sessions: NextSessions, dateformatlabel: dateformatlabel }) );
  }

  // if (LiveSessions.length > 0) {
  //   $liveSessions.html(templateSessions({ sessions: LiveSessions, dateformatlabel: dateformatlabel, listTitle: $liveSessions.data('listtitle') }));
  // } else {
  //   $liveSessions.html($("#no-live-sessions-message").text());
  // }

  // $nextSessions.html(templateSessions({ sessions: NextSessions, dateformatlabel: dateformatlabel, listTitle: $nextSessions.data('listtitle') }));
  // $testSessions.html(templateSessions({ sessions: TestSessions, dateformatlabel: dateformatlabel, listTitle: $testSessions.data('listtitle') }));
  // $testSessionsStaff.html(templateSessions({ sessions: TestSessionsStaff, dateformatlabel: dateformatlabel, listTitle: $testSessionsStaff.data('listtitle') }));
  // $offSessions.html(templateSessions({ sessions: OffSessions  , dateformatlabel: dateformatlabel, listTitle: $offSessions.data('listtitle') }));
}

function dateformatlabel (d) {
  var l = moment(d).isSame(moment(),'day') ? "HH:mm" : "DD/MM HH:mm";
  return moment(d).format(l);
}
